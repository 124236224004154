if (window.location.origin.includes("localhost")) {
    var sitelink = "http://localhost/school-inschrijving/public";
} else {
    var sitelink = window.location.origin;
}


$(document).ready(function () {
    $('input[name=brin]').on('change', function () {
        if ($(this).val().length > 3) {
            getVestigingen($(this).val());
        }
    });
    $('select[name=vestiging]').on('change', function () {
        if ($(this).val().length > 3) {
            getSchool($(this).val());
        }
    });
    $('input[name=user_pass]').on('change', function () {
        passCheck();
    });
    $('input[name=user_pass_repeat]').on('change', function () {
        passCheck();
    });
    $('#inschrijf-form-submit').on('click', function () {
        processInschrijfForm();
    });
    $('#get-demo-form').on('click', function () {
        var brin = $('input[name=brin]').val();
        if (brinExist(brin)) {
            if (brin.length > 2) {
                $('#brin-error').remove();
                window.location.href = window.location.origin + '/' + brin;
            }
        } else {
            if($('#brin-error').length == 0 ){
                $('input[name=brin]').after('<div class="alert alert-danger" id="brin-error">Brin bestaat niet</div>');
            }
        }
    });

    $('input[name=brin]').on('keypress', function(ev){
        if(ev.keyCode === 13 || ev.which === 13){
            var brin = $('input[name=brin]').val();
            if (brinExist(brin)) {
                if (brin.length > 2) {
                    $('#brin-error').remove();
                    window.location.href = window.location.origin + '/' + brin;
                }
            } else {
                if($('#brin-error').length == 0 ){
                    $('input[name=brin]').after('<div class="alert alert-danger" id="brin-error">Brin bestaat niet</div>');
                }
            }
        }
    });
});

function brinExist(brin) {
    var bool = false;
    $.ajax({
        url: sitelink + "/ajax/web/inschrijf/checkBrin/" + brin,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            // "Authorization": "Bearer " + token
        },
        type: "GET",
        async: false,
        // data: JSON.stringify(form_data),
        success: function (data) {
            bool = data;
        },

        error: function (xhr, ajaxOptions, thrownError, data) {


        }
    });
    return bool;
}

function getVestigingen(brin) {
    $.ajax({
        url: sitelink + "/ajax/web/inschrijf/getVestigingen/" + brin,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            // "Authorization": "Bearer " + token
        },
        type: "GET",
        // data: JSON.stringify(form_data),
        success: function (data) {
            setVestigingen(data);
        },

        error: function (xhr, ajaxOptions, thrownError, data) {


        }
    });
}

function getSchool(brin) {
    $.ajax({
        url: sitelink + "/ajax/web/inschrijf/getSchool/" + brin,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            // "Authorization": "Bearer " + token
        },
        type: "GET",
        // data: JSON.stringify(form_data),
        success: function (data) {
            setSchool(data);
        },

        error: function (xhr, ajaxOptions, thrownError, data) {


        }
    });
}

function setVestigingen(vestigingen) {
    if (vestigingen.length === 1) {
        getSchool(vestigingen[0].brin);
    }
    var html = '';
    $.each(vestigingen, function (item) {
        var option = vestigingen[item];
        html += "<option value='" + option.id + "'>" + option.straat + " " + option.huisnummer + "</option>";
    });
    $('select[name=vestiging]').html(html);
}

function setSchool(school) {
    $('#school-name').html(school.name);
    $('#school-leerlingen').html('Aantal leerlingen: ' + school.aantal_leerlingen);
    $('#school-tel').html(school.tel);
    $('#school-straat-nummer').html(school.straat + ' ' + school.huisnummer);
    $('#school-postcode-plaats').html(school.postcode + ' ' + school.plaats);
}

function passCheck() {
    if ($('input[name=user_pass]').val().length > 1 && $('input[name=user_pass_repeat]').val().length > 1) {
        if ($('input[name=user_pass]').val() !== $('input[name=user_pass_repeat]').val()) {
            $('input[name=user_pass]').css('border', '1px solid red');
            $('input[name=user_pass_repeat]').css('border', '1px solid red');
        } else {

            $('input[name=user_pass]').css('border', '1px solid green');
            $('input[name=user_pass_repeat]').css('border', '1px solid green');
        }
    }
}

function processInschrijfForm() {


    if ($('input[name=user_name]').val().length < 1) {
        $('input[name=user_name]').css('border', '1px solid red');

    } else if ($('input[name=user_email]').val().length < 1) {
        $('input[name=user_email]').css('border', '1px solid red');

    } else if (!validateEmail($('input[name=user_email]').val())) {
        $('input[name=user_email]').css('border', '1px solid red');

    } else if ($('input[name=akkoord_voorwaarden]:checked').length === 0) {
        $('.form-check').css('border', '1px solid red');

    }else if ($('input[name=user_email]').val() !== $('input[name=user_email_repeat]').val()) {
        $('input[name=user_email]').css('border', '1px solid red');
        $('input[name=user_email_repeat]').css('border', '1px solid red');

    } else {

        var brin = $('select[name=vestiging]').val();

        checkSchoolUsers(brin);


        $('input[name=user_name]').css('border', '1px solid #ced4da');
        $('input[name=user_email]').css('border', '1px solid #ced4da');
        $('input[name=user_email_repeat]').css('border', '1px solid #ced4da');
        $('.form-check').css('border', '0px solid #ced4da');

        if (checkSchoolUsers(brin)) {
            var error = '<div class="alert alert-danger mt-3">Er is reeds een account aangemaakt voor deze school locatie</div>';
            $('#inschrijf-form-submit').after(error);
        } else {
            $('#inschrijf-form').submit();
        }
    }
}

function validateEmail(email) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(email) == false) {
        // alert('Invalid Email Address');
        return false;
    }

    return true;

}

function checkSchoolUsers(brin) {

    var bool = false;
    $.ajax({
        url: sitelink + "/ajax/web/inschrijf/checkSchoolUsers/" + brin,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            // "Authorization": "Bearer " + token
        },
        type: "GET",
        async: false,
        // data: JSON.stringify(form_data),
        success: function (data) {
            bool = data;

            // setSchool(data);
        },

        error: function (xhr, ajaxOptions, thrownError, data) {


        }
    });
    return bool;
}
