export class Form {
    constructor(formId) {
        if($("#" + formId).length) {
            this.formId = "#" + formId;
        } else {
            throw "Form (#"+formId+") not found, failed to initiate.";
        }

        this.init();
    }

    init() {
        // Find input groups
        const groups = $(this.formId).find(".input-group");

        groups.map((g) => {
            $(groups[g]).find('input').on('focus input change', this.input);
            $(groups[g]).find('input').on('blur focusout', this.blur);

            $(groups[g]).find('textarea').on('focus input change', this.input);
            $(groups[g]).find('textarea').on('blur focusout', this.blur);

            if ($(groups[g]).find('input').val()?.length) {
                $(groups[g]).find('input').siblings('label').addClass('active');
            }

            if ($(groups[g]).find('textarea').val()?.length) {
                $(groups[g]).find('textarea').siblings('label').addClass('active');
            }
        });
    }

    input(e) {
        if(!$(e.target).siblings('label').hasClass('active')) {
            $(e.target).siblings('label').addClass('active')
        }
    }

    blur(e) {
        if($(e.target).siblings('label').hasClass('active') && e.target.value.length === 0) {
            $(e.target).siblings('label').removeClass('active')
        }
    }
}