// import 'bootstrap';


require('./inschrijf');
// require ('owl.carousel');

import $ from 'jquery';
import 'slick-carousel';
import {Form} from "./Form";


$(document).ready(function () {
    new Form('contactform');

    $('#camping-carousel').slick({
        centerMode: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        arrows: false,
        dots: false,
        infinite: true,
        responsive: [
            {
                breakpoint: 993,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

    $('.faq-wrapper .faq-item').on('click', function (e) {
        const current = $('.faq-wrapper .faq-item.active');
        const target = $(this).closest('.faq-item');

        $('.faq-wrapper .faq-item').removeClass('active');

        if(current.length && $(target).is(current)) {
            return
        }

        target.addClass('active');
    })
});


